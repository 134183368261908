/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateRSVPInput = {
  id?: string | null;
  name: string;
  numAdults?: number | null;
  numKids?: number | null;
  notes?: string | null;
};

export type ModelRSVPConditionInput = {
  name?: ModelStringInput | null;
  numAdults?: ModelIntInput | null;
  numKids?: ModelIntInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelRSVPConditionInput | null> | null;
  or?: Array<ModelRSVPConditionInput | null> | null;
  not?: ModelRSVPConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateRSVPInput = {
  id: string;
  name?: string | null;
  numAdults?: number | null;
  numKids?: number | null;
  notes?: string | null;
};

export type DeleteRSVPInput = {
  id?: string | null;
};

export type ModelRSVPFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  numAdults?: ModelIntInput | null;
  numKids?: ModelIntInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelRSVPFilterInput | null> | null;
  or?: Array<ModelRSVPFilterInput | null> | null;
  not?: ModelRSVPFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type CreateRsvpMutation = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

export type UpdateRsvpMutation = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

export type DeleteRsvpMutation = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

export type GetRsvpQuery = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

export type ListRsvPsQuery = {
  __typename: "ModelRSVPConnection";
  items: Array<{
    __typename: "RSVP";
    id: string;
    name: string;
    numAdults: number | null;
    numKids: number | null;
    notes: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateRsvpSubscription = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

export type OnUpdateRsvpSubscription = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

export type OnDeleteRsvpSubscription = {
  __typename: "RSVP";
  id: string;
  name: string;
  numAdults: number | null;
  numKids: number | null;
  notes: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateRsvp(
    input: CreateRSVPInput,
    condition?: ModelRSVPConditionInput
  ): Promise<CreateRsvpMutation> {
    const statement = `mutation CreateRsvp($input: CreateRSVPInput!, $condition: ModelRSVPConditionInput) {
        createRSVP(input: $input, condition: $condition) {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRsvpMutation>response.data.createRSVP;
  }
  async UpdateRsvp(
    input: UpdateRSVPInput,
    condition?: ModelRSVPConditionInput
  ): Promise<UpdateRsvpMutation> {
    const statement = `mutation UpdateRsvp($input: UpdateRSVPInput!, $condition: ModelRSVPConditionInput) {
        updateRSVP(input: $input, condition: $condition) {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRsvpMutation>response.data.updateRSVP;
  }
  async DeleteRsvp(
    input: DeleteRSVPInput,
    condition?: ModelRSVPConditionInput
  ): Promise<DeleteRsvpMutation> {
    const statement = `mutation DeleteRsvp($input: DeleteRSVPInput!, $condition: ModelRSVPConditionInput) {
        deleteRSVP(input: $input, condition: $condition) {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRsvpMutation>response.data.deleteRSVP;
  }
  async GetRsvp(id: string): Promise<GetRsvpQuery> {
    const statement = `query GetRsvp($id: ID!) {
        getRSVP(id: $id) {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRsvpQuery>response.data.getRSVP;
  }
  async ListRsvPs(
    filter?: ModelRSVPFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRsvPsQuery> {
    const statement = `query ListRsvPs($filter: ModelRSVPFilterInput, $limit: Int, $nextToken: String) {
        listRSVPs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            numAdults
            numKids
            notes
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRsvPsQuery>response.data.listRSVPs;
  }
  OnCreateRsvpListener: Observable<OnCreateRsvpSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateRsvp {
        onCreateRSVP {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`
    )
  ) as Observable<OnCreateRsvpSubscription>;

  OnUpdateRsvpListener: Observable<OnUpdateRsvpSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateRsvp {
        onUpdateRSVP {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`
    )
  ) as Observable<OnUpdateRsvpSubscription>;

  OnDeleteRsvpListener: Observable<OnDeleteRsvpSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteRsvp {
        onDeleteRSVP {
          __typename
          id
          name
          numAdults
          numKids
          notes
        }
      }`
    )
  ) as Observable<OnDeleteRsvpSubscription>;
}
