<div style="text-align:center">
  <h1>
    Please RSVP!
  </h1>
</div>
<div class="rsvp-container" align="center">
<table>
  <tr>
  <mat-form-field>
    <input matInput
           [(ngModel)]="currentRSVP.name"
           placeholder="Your Name">
  </mat-form-field>
</tr>
<tr>
  <mat-form-field>
    <mat-select [(value)]="currentRSVP.numAdults"
                placeholder="How many adults?">
      <mat-option value="1">1</mat-option>
      <mat-option value="2">2</mat-option>
      <mat-option value="3">3</mat-option>
      <mat-option value="4">4</mat-option>
    </mat-select>
  </mat-form-field>
</tr>
<tr>
  <mat-form-field>
    <mat-select [(value)]="currentRSVP.numKids"
                placeholder="How many kids?">
      <mat-option value="0">0</mat-option>
      <mat-option value="1">1</mat-option>
      <mat-option value="2">2</mat-option>
      <mat-option value="3">3</mat-option>
      <mat-option value="4">4</mat-option>
    </mat-select>
  </mat-form-field>
</tr>
<tr><td width=400>
  <mat-form-field class="form-field-notes">
    <textarea matInput class="textarea-noes"
              [(ngModel)]="currentRSVP.notes"
              placeholder="Notes: Things you want us to know."></textarea>
  </mat-form-field>
</td></tr>
<tr><td>
  <button mat-Button
          type="button"
          mat-raised-button
          (click)="onSubmit()">Submit
  </button>
</td></tr>
</table>
</div>
