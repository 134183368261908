import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { APIService } from '../API.service';

export interface RSVP {
  id: string;
  name: string;
  numAdults: number;
  numKids: number;
  notes: string;
}

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})

export class RsvpComponent implements OnInit {
  dataRSVP: Array<any> = [];
  currentRSVP: RSVP = {
    id: '',
    name: '',
    numAdults: 0,
    numKids: 0,
    notes: '',
  };
  constructor(
    private apiService: APIService) { }

  async ngOnInit() {
    this.apiService.ListRsvPs().then((evt) => {
      this.dataRSVP = evt.items;
    })
    this.apiService.OnCreateRsvpListener.subscribe((evt) => {
      //console.log('create');
      //console.log(evt);
      const data = (evt as any).value.data.onCreateTodo;
      this.dataRSVP = [...this.dataRSVP, data];
    });
    this.apiService.OnUpdateRsvpListener.subscribe((evt) => {
      console.log('update');
      console.log(evt);
      //const data = (evt as any).value.data.onCreateTodo;
      //this.todos = [...this.todos, data];
    });
  }
  
  onSubmit() {
    this.apiService.CreateRsvp({
      name: this.currentRSVP.name,
      numAdults: this.currentRSVP.numAdults,
      numKids: this.currentRSVP.numKids,
      notes: this.currentRSVP.notes,
    });
    console.log('submit');
  }

}
